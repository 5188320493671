import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const experience = () => {
  return (
    <section id='experience'>
      <h5>Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        <div className="experience__development">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            <article className='"experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4>HTML</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='"experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4>CSS</h4>
              <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className='"experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4>JavaScript</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='"experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4>WordPress</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='"experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4>React</h4>
              <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className='"experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4>SEO</h4>
              <small className='text-light'>Intermediate</small>
              </div>
            </article>
          </div>
        </div>
        {/* END OF FRONTEND */}
        <div className="experience__IT">
        <h3> IT Support</h3>
          <div className="experience__content">
            <article className='"experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4>Customer Support</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='"experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4>Linux</h4>
              <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className='"experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4>Domain Name System (DNS)</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='"experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4>Troubleshooting & Debugging</h4>
              <small className='text-light'>Experienced</small>
              </div>
            </article>

            <article className='"experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4>Encryption Algorithms & Techniques</h4>
              <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className='"experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4>Network Model & Protocols</h4>
              <small className='text-light'>Intermediate</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default experience